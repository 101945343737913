import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import _ from "underscore";

import BlueButton from "../../../../components/BlueButton";
import ClearIcon from "@mui/icons-material/Clear";
import UserData from "../../../../data/UserData";
import { GroupType } from "../../../../data/MessageData";
import { UserType } from "../../../../data/OrganizationData";
import useClients from "../../../../hooks/useClients";
import organizationIdSelector from "../../../../atoms/organizationIdSelector";
import useRecipes from "../../../../hooks/useRecipes";

const GroupTypeBox = ({ isAdd, type, onChange }) => {
  if (isAdd) {
    return (
      <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
        <Typography style={{ width: 200 }}>Group Type:</Typography>
        <Select
          style={{ width: 350 }}
          variant="outlined"
          value={type}
          onChange={onChange}
        >
          <MenuItem value="group">Custom Group</MenuItem>
          <MenuItem value="alert">Alert Group</MenuItem>
        </Select>
      </Box>
    );
  }
  return null;
};

const AddGroupDialog = (props) => {
  const organizationId = useRecoilValue(organizationIdSelector);
  const { clients } = useClients({ organizationId });
  const { getMealPlanById, mealPlans } = useRecipes({ organizationId });

  const [name, setName] = React.useState(props.group ? props.group.name : "");
  const [type, setType] = React.useState(
    props.group ? props.group.type : GroupType.group,
  );
  const [global, setGlobal] = React.useState(
    props.group ? (props.group.global ? props.group.global : false) : false,
  );
  const [error, setError] = React.useState(null);

  const [organizationUsers, setOrganizationUsers] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [filter, setFilter] = React.useState("");

  function validate() {
    if (name.length === 0) {
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    async function loadData() {
      const users = await UserData.getOrganizationUsers();

      setOrganizationUsers(users);
    }

    loadData();

    if (props.group) {
      setSelectedUsers(props.group.users || []);
    } else {
      setSelectedUsers([localStorage.getItem("uid")]);
    }
  }, [props.group]);

  React.useEffect(() => {
    // filter users depending on the group type.
    let filteredUsers = organizationUsers;
    if (
      props.group &&
      (props.group.type === GroupType.single ||
        props.group.type === GroupType.questions)
    ) {
      filteredUsers = organizationUsers.filter((u) => {
        return u.type === UserType.coach;
      });
    }

    filteredUsers = filteredUsers.map((u) => {
      const client = getClientByUserId(u.uid);
      return {
        ...u,
        mealPlan: getMealPlanById(client?.mealPlanId) || {
          name: "No Meal Plan",
        },
      };
    });

    if (filter.length > 0) {
      filteredUsers = filteredUsers.filter((u) => {
        return (
          u.firstName.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          u.lastName.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          u.mealPlan?.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        );
      });
    }

    setFilteredUsers(filteredUsers);

    if (!props.group) {
      setName("");
      setType(GroupType.group);
    } else {
      setName(props.group.name);
      setType(props.group.type);
    }
  }, [
    organizationUsers,
    props.group,
    clients,
    filter,
    mealPlans,
    getMealPlanById,
  ]);

  function addSelected(user) {
    const found = _.find(selectedUsers, (userId) => {
      return userId === user.uid;
    });
    if (!found) {
      const newSelected = [].concat(selectedUsers);
      newSelected.push(user.uid);
      setSelectedUsers(newSelected);
    }
  }

  function changeGlobal(event) {
    const newValue = event.target.checked;
    setGlobal(newValue);

    if (newValue != true) {
      deselectAllUsers();
    } else {
      selectAllUsers(organizationUsers);
    }
  }

  function removeSelected(user) {
    if (props.group && props.group.type === GroupType.single) {
      if (user.uid === props.group.userId) {
        return;
      }
    }

    if (user.uid === localStorage.getItem("uid")) {
      return;
    }
    // if (user.role === UserType.admin) {
    //   return;
    // }
    const newSelected = _.reject(selectedUsers, (userId) => {
      return userId === user.uid;
    });
    setSelectedUsers(newSelected);
  }

  function isSelected(user) {
    const found = _.find(selectedUsers, (userId) => {
      return userId === user.uid;
    });
    return found !== undefined;
  }

  function selectAllUsers(users) {
    const newSelected = [localStorage.getItem("uid")];
    users.forEach((user) => {
      newSelected.push(user.uid);
    });
    setSelectedUsers(newSelected);
  }

  function deselectAllUsers(users) {
    setSelectedUsers([]);
  }

  function selectNoneUsers() {
    const adminIDs = organizationUsers
      .filter((u) => {
        return u.role === UserType.admin;
      })
      .map((u) => {
        return u.uid;
      });

    // remove the current user from adminIDs
    const index = adminIDs.indexOf(localStorage.getItem("uid"));
    if (index > -1) {
      adminIDs.splice(index, 1);
    }

    const newSelected = [localStorage.getItem("uid"), ...adminIDs];
    setSelectedUsers(newSelected);
  }

  const getClientByUserId = React.useCallback(
    (uid) => {
      const found = clients.find((c) => {
        return c.uid === uid;
      });
      return found;
    },
    [clients],
  );

  return (
    <Dialog
      // style={{ height: 800 }}
      fullWidth
      maxWidth="lg"
      open={true}
      onClose={() => {
        if (props.onDialogClosed) {
          props.onDialogClosed(null);
        }
      }}
    >
      <DialogTitle>
        {props.mode === "edit" ? "Edit Message Group" : "Add Message Group"}
      </DialogTitle>

      <DialogContent style={{}}>
        <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
          <Typography style={{ width: 200 }}>Message Group Name:</Typography>
          <TextField
            value={name}
            style={{ width: 350 }}
            variant="outlined"
            onChange={(event) => {
              setName(event.target.value);
            }}
          ></TextField>
        </Box>

        <GroupTypeBox
          isAdd={props.mode === "edit" ? false : true}
          type={type}
          onChange={(event) => {
            setType(event.target.value);
          }}
        />

        {type == "group" || type == "alert" ? (
          <Box style={{ display: "flex", alignItems: "center", marginTop: 18 }}>
            <Typography style={{ width: 200 }}>Global:</Typography>
            <Checkbox
              checked={global}
              onChange={(isChecked) => {
                changeGlobal(isChecked);
              }}
            ></Checkbox>
          </Box>
        ) : null}

        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginBottom: 8,
            marginTop: 12,
          }}
        >
          Select users for the group:
        </Typography>
        <Stack
          direction={"row"}
          spacing={2}
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 8,
            marginBottom: 0,
          }}
        >
          <Typography>Search:</Typography>
          <TextField
            value={filter}
            style={{ minWidth: 300 }}
            onChange={(event) => {
              setFilter(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: "visible" }}
                  onClick={() => {
                    setFilter("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
          <Button onClick={() => selectAllUsers(filteredUsers)}>
            Select All
          </Button>
          <Button onClick={() => selectNoneUsers(filteredUsers)}>
            Select None
          </Button>
        </Stack>

        <>
          <Grid container style={{ marginBottom: 12 }}>
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: "bold" }}>Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Meal Plan
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1}>
              <Typography style={{ fontWeight: "bold" }}>Selected</Typography>
            </Grid>
          </Grid>
        </>
        <Box style={{ maxHeight: 350, overflow: "auto" }}>
          {filteredUsers
            ? filteredUsers.map((user) => {
                return (
                  <Grid key={user.uid} container>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{`${
                            user.mealPlan ? user.mealPlan.name : "No Meal Plan"
                          }`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={1}>
                      <Checkbox
                        indeterminate={false}
                        checked={isSelected(user)}
                        onChange={() => {
                          if (isSelected(user)) {
                            removeSelected(user);
                          } else {
                            addSelected(user);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Box>
        <Box style={{ marginTop: 12 }}>
          <Divider />
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Selected: {selectedUsers.length}
          </Typography>
        </Box>
      </DialogContent>

      {error && (
        <DialogContent>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 200, color: "red" }}>
              {error}
            </Typography>
          </Box>
        </DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            if (props.onDialogClosed) {
              props.onDialogClosed(null);
            }
          }}
          color="primary"
        >
          Cancel
        </Button>
        <BlueButton
          onClick={() => {
            if (validate()) {
              if (props.onDialogClosed) {
                props.onDialogClosed(
                  {
                    name: name,
                    users: selectedUsers,
                    type: type,
                    global: global,
                  },
                  props.mode,
                  props.group,
                );
              }
            } else {
              setError("Message group name is required.");
            }
          }}
          color="primary"
        >
          Save
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddGroupDialog;
